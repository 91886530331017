.carousel-container {
  width: 80%;                   /* Adjust the width as needed */
  margin: 0 auto;
  padding: 20px 0;
}

.carousel-image {
  width: 100%;
  height: 500px;                /* Adjust the height based on your design */
  object-fit: cover;            /* Ensure the image covers the area */
  border-radius: 10px;          /* Optional styling */
}

.carousel-container {
  padding: 20px; /* Optional: Add padding around the carousel */
}

.carousel-item {
  padding: 0 50px; /* Add horizontal padding to create a gap between slides */
}

.carousel-item img {
  border-radius: 10px; /* Optional: Round the image corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow to the images */
}

/* Add custom styles for arrows */
.custom-next-arrow, .custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.custom-next-arrow:before, .custom-prev-arrow:before {
  color: white;
  font-size: 20px;
}

/* Position for custom next arrow */
.custom-next-arrow {
  right: 10px;
}

/* Position for custom prev arrow */
.custom-prev-arrow {
  left: 10px;
}
.custom-next-arrow {
  display: block;
  right: 10px;
}

.custom-prev-arrow {
  display: block;
  left: 10px;
  z-index: 1;
}

/* Add custom styles for arrows */
.custom-next-arrow, .custom-prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.custom-next-arrow:before, .custom-prev-arrow:before {
  color: white;
  font-size: 20px;
}

/* Position for custom next arrow */
.custom-next-arrow {
  right: 10px;
}

/* Position for custom prev arrow */
.custom-prev-arrow {
  left: 10px;
}


.carousel-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-description {
  background-color: rgba(3, 3, 3, 0.5);
  margin: 0 1em;
}

.section-with-bg {
  position: relative;
  background-color: #0a0000; /* Set this to the color that matches the image background */
  padding: 50px 0; /* Adjust as needed for content space */
}

/* Add the top image */
.section-with-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px; /* Adjust height based on the top image size */
  background: url('../assets/DescriptionBottom.png') no-repeat top center;
  background-size: cover;
}

/* Add the bottom image */
.section-with-bg::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px; /* Adjust height based on the bottom image size */
  background: url('../assets/DescriptionBottom.png') no-repeat bottom center;
  background-size: cover;
}
.about {
  padding: 20px;
}

.mission, .team, .values, .achievements {
  margin-bottom: 40px;
}

.mission h2, .team h2, .values h2, .achievements h2 {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

/* Values list styling */
.values ul.no-bullets {
  list-style: none;
  padding: 0;
}

.values ul.no-bullets li {
  font-size: 1.2rem;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

/* Achievements Section */
.achievements ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.achievements li {
  margin: 10px; /* Space between logos */
}

.achievements li img {
  max-width: 100%; /* Ensure images don't exceed their container */
  height: auto;
}

/* Adjustments for large screens (above 1200px) */
@media (min-width: 1200px) {
  .achievements li {
    flex: 1 0 25%; /* Four logos per row */
  }
}

/* Adjustments for medium screens (768px to 1199px) */
@media (max-width: 1199px) and (min-width: 768px) {
  .achievements li {
    flex: 1 0 33%; /* Three logos per row */
  }
}

/* Adjustments for small screens (below 768px) */
@media (max-width: 767px) {
  .achievements li {
    flex: 1 0 50%; /* Two logos per row */
  }
}

/* Adjustments for very small screens (below 468px) */
@media (max-width: 468px) {
  .achievements li {
    flex: 1 0 100%; /* One logo per row */
  }
}
