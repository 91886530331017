/* Global Styles */
:root {
  --primary-green: #58b368;
  --primary-blue: #1e3d59;
  --background-dark: #0a0f12; /* Dark background color #111111 */ 
  --text-light: #c0c7d1; /* Light text color #c0c7d1  old #ffffff*/
  --text-gray: #888888;
  --text-highlight:  rgb(183, 228, 115);
  --text-body: UntitledSans, sans-serif;
}

body {
  font-family: 'Inter', sans-serif; /* Clean modern font 'Inter', 'Helvetica Neue', sans-serif; */
  line-height: 1.6;
  color: var(--text-light);
  background-color: var(--background-dark);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}


/* Header Styles */
.header {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background: rgba(17, 17, 17, 0.8);
  /* WebKit (Safari) specific backdrop filter */
  -webkit-backdrop-filter: blur(10px);
  /* Standard backdrop filter for most browsers */
  backdrop-filter: blur(10px);
  /* Ensure element is at least partially transparent */
  background-color: rgba(12, 13, 13, 0.5); /* Example for semi-transparent background */
  transition: background-color 0.3s ease, padding 0.3s ease;
  height: 80px;
  z-index: 10;
}


/* When the page is scrolled */
.header.scrolled {
  background-color: white;
  height: 80px;
  color: black;
}
.header.scrolled .hamburger .bar {
  background-color: black;
}
.header.scrolled .nav-link {
  color: black;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 75px; /* Half of 403px */
  /* height: 25px; Half of 100px */
  object-fit: contain; /* This ensures the entire logo is visible */
}
/* 
nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 1.5rem;
}

nav ul li a {
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 1rem;
}

nav ul li a:hover {
  color: var(--primary-green);
}
 */
/* Scrolled state with hover */
.header.scrolled .nav-link:hover {
  color: var(--primary-green); /* Ensure the hover state also applies when scrolled */
}

/* Home Page Styles */
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(45deg, rgba(30, 61, 89, 0.8), rgba(88, 179, 104, 0.8));
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {background-position: 0% 50%;}
  50% {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
}

.hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease 0.5s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--primary-green);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-blue);
}

  /* About Page Styles */
  .about {
    padding: 5rem 0;
  }
  
  .about h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: var(--primary-green);
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
/* Services Page Styles */
.services {
  padding: 5rem 0;
}

.services h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--primary-green);
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.service-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-item h2 {
  color: var(--primary-green);
  margin-bottom: 1rem;
}
/* Contact Page Styles */
.contact {
  padding: 5rem 0;
}

.contact h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--primary-green);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 5px;
  color: var(--text-light);
}

.contact-form button {
  background-color: var(--primary-green);
  color: var(--text-light);
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: var(--primary-blue);
}


/* Features Section */
.features {
  padding: 5rem 0;
}

.feature {
  margin-bottom: 5rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.feature.visible {
  opacity: 1;
  transform: translateY(0);
}

.feature h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-green);
}

.feature p {
  font-size: 1.2rem;
  color: var(--text-gray);
}
/* Footer Styles */
.footer {
  background: rgba(255, 255, 255, 0.05);
  padding: 3rem 0;
  margin-top: 5rem;
}
footer div.container {
margin-bottom: 10em;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex-basis: calc(25% - 2rem);
  margin-bottom: 2rem;
}

.footer-section h3 {
  color: var(--primary-green);
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a {
  color: var(--text-gray);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--text-light);
}
/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .header {
    padding: 0.5rem 1rem;
  }

  .header .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo {
    margin-bottom: 0.5rem;
  }

  .logo-image {
    width: 230px; /* Even smaller on mobile */
    height: 19px; /* Maintaining aspect ratio */
  }

  nav {
    width: 100%;
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: var(--text-light);
  font-size: 1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    margin-bottom: 0.5rem;
  }

  nav:not(.open) ul {
    display: none;
  }
}
