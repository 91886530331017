/* src/components/Chatbot.css */
/* Chatbot styles */
/* Ensure chatbox is above other elements */
.chatbot-container {
  z-index: 199;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
/* Chatbot container */
.chatbot-container {
  background-color: #fff; /* Adjust if needed */
  border-radius: 10px; /* Make sure this matches the header */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent overflow */
  width: 100%; /* Ensure full width */
  max-width: 400px; /* Adjust the max width of the chat box */
}
/* Chatbot header */
.chatbot-header {
  background-color: #007bff; /* Assuming the header background is blue */
  color: #fff; /* Ensure text is white */
  padding: 10px;
  border-top-left-radius: 10px; /* Make sure radius matches container */
  border-top-right-radius: 10px; /* Ensure no corner cuts */
  margin: 0; /* Remove any margin */
  width: 100%; /* Ensure header stretches fully */
  box-sizing: border-box; /* Prevent padding from messing with width */
}

.chatbot-body {
  padding: 15px;
}


.chatbot-message {
  color: black; 
  margin-bottom: 15px;
}


.chatbot-input {
  width: 90%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chatbot-btn {
  background-color: #007bff;
  color: white;
  padding: 8px;
  width: 95%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chatbot-btn:hover {
  background-color: #45a049;
}

.chatbot-button {
  background-color: #1e1e1e;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.chatbot-button:hover {
  background-color: #333;
}

/* Questions list for previous questions */
.questions-list {
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
}
div.h5 {
  color: black;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.chatbot-message p {
  margin-top: 0;
  margin-bottom: 1rem;
  color: black;
}
.questions-list li {
  color: black;
  background-color: #f1f1f1;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.email-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}



.chatbot-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.chatbot-container {
  /* Make sure there's no unwanted overflow */
  overflow: hidden;
}

/* Ensure the chatbot header fills 100% width */
.chatbot-header {
  background-color: #007bff; /* Blue background */
  color: #fff; /* White text */
  /* padding: 10px; */
  border-top-left-radius: 10px; /* Ensure rounded corners */
  border-top-right-radius: 10px; /* Ensure rounded corners */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Prevent padding from affecting the width */
  margin: 0;
}

/* Ensure the container also has the correct width and layout */
.chatbot-container {
  width: 100%;
  max-width: 350px; /* Adjust max width based on design */
  background-color: #fff; /* White background for content */
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
}

.react-tel-input .form-control {
  width: 95%;
}
