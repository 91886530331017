/* Container */
.circular-animation-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cow-image-container {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;  /* Always keep the cow on top */
}  
.cow-image {
  width: 30%;
  height: auto;
  position: relative;
  /* background-color: rebeccapurple; */
}

@keyframes rotatePelletFeeds {
  0% {
    transform: translate(0%, 0%) scale(0.75);
    z-index: 3; /* Front */
  }
  25% {
    transform: translate(-200%, -0%)  scale(0.5);
    z-index: 3; /* Back (top-right of the oval) */
  }
  50% {
    transform: translate(-400%, -0%) scale(0.55);
    z-index: 5; /* Back (far right of the oval) */
  }
  75% {
    transform: translate(-200%, 20%) scale(1);
    z-index: 5; /* Back (bottom-right of the oval) */
  }
  100% {
    transform: translate(0%, 0%) scale(0.75);
    z-index: 5; /* Front (returns to start) */
  }
}
.feed-image-pellet {
  position: absolute;
  width: 20%;
  height: auto;
  right: 0%;
  transition: all 1.5s ease-in-out;
  animation: rotatePelletFeeds 8s linear infinite;
}

@keyframes rotateDryFeeds {
  0% {
    transform: translate(-0%, 0%) scale(1);
    z-index: 6; /* Front */
  }
  25% {
    transform: translate(200%, -10%)  scale(0.75);
    z-index: 6; /* Back (top-right of the oval) */
  }
  50% {
    transform: translate(0%, -20%) scale(0.50);
    z-index: 2; /* Back (far right of the oval) */
  }
  75% {
    transform: translate(-200%, -10%) scale(0.55);
    z-index: 6; /* Back (bottom-right of the oval) */
  }
  100% {
    transform: translate(0%, 0%) scale(1);
    z-index: 6; /* Front (returns to start) */
  }
}
.feed-image-dry {
  position: absolute;
  width: 20%;
  height: auto;
  left: 40%;
  transition: all 1.5s ease-in-out;
  animation: rotateDryFeeds 8s linear infinite;
}



@keyframes rotateWetFeeds {
  0% {
    transform: translate(-0%, 0%) scale(0.65);
    z-index: 7; /* Front */
  }
  25% {
    transform: translate(200%, 0%)  scale(1);
    z-index: 7; /* Back (top-right of the oval) */
  }
  50% {
    transform: translate(400%, 0%) scale(0.65);
    z-index: 7; /* Back (far right of the oval) */
  }
  75% {
    transform: translate(250%, -0%) scale(0.5);
    z-index: 1; /* Back (bottom-right of the oval) */
  }
  100% {
    transform: translate(0%, 0%) scale(0.65);
    z-index: 7; /* Front (returns to start) */
  }
}
.feed-image-wet {
  position: absolute;
  width: 20%;
  height: auto;
  left: 0%;
  transition: all 1.5s ease-in-out;
  animation: rotateWetFeeds 8s linear infinite;
}

.feed-image {
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: rebeccapurple; */
}
.feed-image-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}
