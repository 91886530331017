.genomics-image {
  width: 70%;
  height: auto;
  }

  .output-container {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    }  

    .output-graph {
      width: 100%;
      height: auto;
    }
    


    .output-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5em;
      font-weight: bold;
      color: white;
      text-shadow: 2px 2px 4px #000000;
    }
    .service-item ul li{
      width: 100%;
      height: auto;
      margin-bottom: 1em;
    }
    .output-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      font-weight: bold;
      color: white;
      text-shadow: 2px 2px 4px #000000;
    }