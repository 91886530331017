.logo-inline {
    display: inline-block;
    width: 10px; /* Adjust width to fit */
    height: auto; /* Maintain aspect ratio */
    vertical-align: middle; /* Align with the text */
}
.agri-twin-logo {
    width: auto;
    height: 0.75em;
    margin: 0;
    padding: 0;
}