/* pages/Home.css */
.digital-twin-homepage {
  padding-top: 80px; /* Push content down by 80px (or the height of the header) */
  flex-direction: row;
  justify-content: center;

}

p.text-xl.text-white.mb-4.text-center {
  margin: 0 1rem;
}

.image-fade-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* Maintain 16:9 aspect ratio */
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.black-sheet {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.5) 35%,
    rgba(0, 0, 0, 0.6) 60%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 3;
}

.fade-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 4s ease-in-out; /* 4 seconds fade transition */
}

.bottom-image {
  z-index: 1;
}

.top-image {
  z-index: 2;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

/* Overlay container positioned on the left 50% of the image */
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Restrict to left 50% */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5; /* Ensure the overlay is above the images */
  background: rgba(0, 0, 0, 0); /* Optional semi-transparent background */
}

/* Styling for the text in the overlay */
.overlay-text {
  font-size: 3rem;
  font-weight: 300;
  width: 45%;
  color: white;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
  box-sizing: border-box;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  font-weight: 300;
  letter-spacing: -0.05rem;
  line-height: 1.2;
  font-family: var(--text-body);
}

.bottom-overlay-text {
  width: 45%;
  color: white;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
  box-sizing: border-box;
  margin-left: 1rem;
  margin-bottom: 4rem;
  font-weight: 200;
  font-size: 1.75rem;
  letter-spacing: -0.05rem;
  line-height: 1.2;
  font-family: var(--text-body);
}

/* Media Queries */
@media (max-width: 900px) {
  .overlay-text,
  .bottom-overlay-text {
    width: 50%;
  }
  .overlay-text {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .bottom-overlay-text {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .overlay-text,
  .bottom-overlay-text {
    width: 50%;
  }
  .overlay-text {
    margin-top: 4rem;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  .bottom-overlay-text {
    font-size: 1rem;
  }
}

@media (max-width: 486px) {
  .overlay-text,
  .bottom-overlay-text {
    width: 50%;
  }
  .overlay-text {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .bottom-overlay-text {
    font-size: 0.75rem;
  }
}
