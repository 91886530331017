/* Header styles */
/* General header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: black;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease, padding 0.3s ease;
  z-index: 10;
  height: 80px;
}

.header.scrolled {
  background-color: white;
  height: 80px;
}

.logo {
  height: 20px;
  margin-left: 20px;
  transition: transform 0.3s ease;
}

/* Hamburger Menu for mobile */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 3em;
  z-index: 15;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: background-color 0.3s ease;
}

.header.scrolled .hamburger .bar {
  background-color: black;
}

/* Slide-in nav-menu */
.nav-menu {
  position: fixed;
  top: 0;
  right: -100%; /* Hidden off-screen */
  width: 250px;
  height: 320px;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transition: right 0.5s ease;
  z-index: 14;
}

.nav-menu.open {
  right: 0; /* Slide-in effect */
}


.nav-menu ul {
  list-style: none;
  padding: 0;
  text-align: flex-end;
  width: 100%;
}

.nav-menu ul li {
  margin: 20px 0;
}

.nav-menu ul li a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.header.scrolled .nav-menu ul li a {
  color: black; /* Change link color when scrolled */
}

.nav-link {
  display: flex;
  gap: 20px;
  margin-right: 3em;
}

.nav-link ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-link ul li {
  margin-left: 1.5rem;
}

.nav-link ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.header.scrolled .nav-link ul li a {
  color: black;
}

/* Hide nav-links and show hamburger for smaller devices */
@media (max-width: 768px) {
  .nav-link {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}

/* Media query for showing hamburger and sliding menu */
@media (max-width: 486px) {
  .hamburger {
    display: flex;
    margin-right: 2em;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
  }

  .nav-menu.open {
    right: 2em; /* Slide the menu in */
    width: 120px;
    top: 6em;
    background-color: rgba(0, 0, 0, 0.9);
    padding-right: 1em;
  }
  .header.scrolled .nav-menu.open {
    background-color: white;
    color: black;
  }
  .nav-menu.open ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    margin-right: 1em;
  }

  .nav-menu.open ul li {
    margin: 1em 0;
  }

  .nav-menu ul {
    padding: 0;
  }

}
