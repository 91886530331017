/* src/components/JerseyCowTwinAnimation.css */
.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* background-color: red; */
  padding-top: 20px; /* Add padding to move it closer to the text above */
}
.cow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: blue; */
}
.dna-cow-image {
  max-width: 100%;
  height: auto;
}

.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: green; */
}

.twin-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: yellow; */
}

.dna-twin-image {
  max-width: 100%;
  height: auto;
}


.centered-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top, .bottom {
  padding: 10px 0;
  font-size: 20px; /* Adjust the font size */
  color: #fff; /* White text */
}

.flow-line {
  width: 100%; /* Full width to span between the two text items */
  height: 5px;
  background: linear-gradient(90deg, transparent, #00FF00, transparent); /* Neon green line effect */
  background-size: 200% 100%;
  animation: flow 2s linear infinite; /* Continuous animation */
}

/* Keyframe animation to create the flowing data effect */
@keyframes flow {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
